var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "view-producer" }, [
    _c("header", { staticClass: "header header--borderBottom" }, [
      _c("div", { staticClass: "container headerContainer" }, [
        _c("div", { staticClass: "headerContainer__heading" }, [
          _c("h3", [_vm._v("Sales")]),
          _c("h1", [_vm._v("Order Details #" + _vm._s(_vm.id))]),
        ]),
        _c(
          "div",
          { staticClass: "headerContainer__alignRight" },
          [
            _c(
              "router-link",
              {
                staticClass:
                  "btn btn--sm btn--black btn--text btn--withIconLeft",
                attrs: { to: { name: "Orders", params: { pageIndex: 1 } } },
              },
              [
                _c("icon", {
                  attrs: { name: "arrow-left", width: "14", height: "14" },
                }),
                _vm._v("GO BACK"),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]),
    _c("section", { staticClass: "section" }, [
      _vm.isLoading
        ? _c(
            "div",
            { staticClass: "container container--fullWidth" },
            [_c("VLoadSpinner")],
            1
          )
        : !_vm.isLoading && !_vm.orderDetails
        ? _c("div", { staticClass: "container container--fullWidth" }, [
            _c("h2", [_vm._v("Order not found")]),
          ])
        : _c(
            "div",
            { staticClass: "container container--fullWidth grid grid__col2" },
            [
              _c("div", { staticClass: "order-details" }, [
                _c("p", { staticClass: "order-details__item" }, [
                  _vm._v("Customer Email "),
                  _c("span", [_vm._v(_vm._s(_vm.orderDetails.email))]),
                ]),
                _c("p", { staticClass: "order-details__item" }, [
                  _vm._v("Date "),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm._f("moment")(
                          _vm.orderDetails.date,
                          "ddd, MMMM Do YYYY - h:mm a"
                        )
                      )
                    ),
                  ]),
                ]),
                _c("p", { staticClass: "order-details__item" }, [
                  _vm._v("Items Ordered "),
                  _c("span", [
                    _vm._v(_vm._s(_vm.orderDetails.bought_beats.length)),
                  ]),
                ]),
                _c("p", { staticClass: "order-details__item" }, [
                  _vm._v("Total "),
                  _c("span", [_vm._v("$" + _vm._s(_vm.orderTotal))]),
                ]),
              ]),
              _c("div", { staticClass: "wrapper--filled" }, [
                _c("table", [
                  _vm._m(0),
                  _c(
                    "tbody",
                    _vm._l(_vm.orderDetails.bought_beats, function (beat) {
                      return _c("tr", { staticClass: "order-details" }, [
                        _c("td", [_vm._v(_vm._s(beat.beat.name))]),
                        _c("td", [_vm._v(_vm._s(beat.license.name))]),
                        _c("td", [_vm._v("$" + _vm._s(beat.producer_income))]),
                      ])
                    }),
                    0
                  ),
                ]),
              ]),
            ]
          ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", { staticClass: "order-details" }, [
        _c("th", [_vm._v("NAME")]),
        _c("th", [_vm._v("LICENSE")]),
        _c("th", [_vm._v("TOTAL")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }